// import React from "react";
// import "./Footer.css";
// import badge from "./badge.png";
// import apple from "./apple.png";
// import aqt from "../../assets/images/aqt.png";
// import { Link } from "react-router-dom";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import EmailIcon from "@mui/icons-material/Email";
// import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
// import TelegramIcon from "@mui/icons-material/Telegram";
// import XIcon from "@mui/icons-material/X";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import YouTubeIcon from "@mui/icons-material/YouTube";

// export default function Footer() {
//   return (
//     <>
//       <div className="mainFooter">
//         <div className="blocks">
//           <div className="block1">
//             <img src={aqt} alt="footer" />
//             <p>
//               AQT Direct Ltd is a distinguished Private Equity Consultancy
//               dedicated to assisting individuals in becoming Partners and Angel
//               Investors in Early-Stage, High-Growth Potential Public Businesses
//               gearing up for Initial Public Offerings (IPOs). We provide a
//               comprehensive range of services essential for Private Equity
//               Investors, including Market Research, Due Diligence, Auditing,
//               Transaction Execution, and Compliance of Private Placements.
//               Additionally, we offer Legal Support, Deal Documentation, Value
//               Creation Consultation, Industry Specialized Growth Consultation,
//               Strategies for Exiting Investments, and Investor Management
//               Software. By engaging with AQT Direct Ltd and utilizing our
//               services, individuals acknowledge and accept the inherent risks
//               associated with investing in early-stage businesses and agree to
//               hold our consultancy harmless from any liabilities arising from
//               investment activities.
//             </p>
//             {/* <div style={{display:"flex"}}>
//             <a  href='/'>
//                 <img className='app' src={apple} alt='apple'/></a>
//             <a  href='/'>
//                 <img className='app' src={badge} alt='playstore'/></a>
//                 </div> */}
//           </div>
//           {/* <div className="block2">
//             <h1 className="anchorfoot">Quick Links</h1>
//             <div className="anchor">
//               <a className="insideanch" href="/off-market">
//                 Off Market Annexure
//               </a>
//               <Link className="insideanch" to="/sebiguidelines">
//                 SEBI Guidelines
//               </Link>
//               <Link className="insideanch" to="/frequently-asked-questions">
//                 Frequently Asked Questions
//               </Link>
//               <Link to={`/knowledgecenter`} className="insideanch" href="/">
//                 Knowledge Center
//               </Link>
//               <Link to={`/blog`} className="insideanch" href="/">
//                 Blog
//               </Link>
//               <a className="insideanch" href="/">
//                 Live Platform
//               </a>
//             </div>
//           </div> */}

//           <div className="block2">
//             <h1 className="anchorfoot">Quick Links</h1>
//             <div className="anchor">
              
//               <Link className="insideanch" to="/about">
//                 about Us
//               </Link>
//               <Link className="insideanch" to="/fundraise">
//               fundraise 
//               </Link>
//               <Link to={`/smeloans`} className="insideanch" href="/">
//               smeloans 
//               </Link>
//               <Link to={`/contactus`} className="insideanch" href="/">
//               contactus
//               </Link>
              
//             </div>
//           </div>

//           <div className="block4">
//             <div>
//               <h1 className="anchorfoot1">Our Offices</h1>
//               <p className="pfooter">
//                 <LocationOnIcon />
//                 &nbsp;
//                 <b>Mumbai Office : </b>
//                 {/* Office No. 616 ,<br/>&nbsp;Sixth Floor, Tower A,<br/> &nbsp;AQT DIRECT LIMITED - <br/>&nbsp; ithum Building,Sector 62, Noida,<br/>&nbsp;Uttar Pradesh 201301 */}
//                 &nbsp;&nbsp;&nbsp;&nbsp;Enam sambhav C-20, <br />
//                 &nbsp;&nbsp;&nbsp;&nbsp;G block Bandra Kurla Complex <br />{" "}
//                 &nbsp;&nbsp;&nbsp;&nbsp;Mumbai ,Maharashtra 400051
//               </p>

//               <p className="pfooter">
//                 <LocationOnIcon />
//                 &nbsp;
//                 <b>Noida Office : </b>
//                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A-616 iTHUM
//                 BUILDING <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; NOIDA
//                 SECTOR 62 , <br />{" "}
//                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;UTTAR PREADESH
//                 201309
//               </p>
//               <p className="pfooter">
//                 <LocationOnIcon />
//                 &nbsp;
//                 <b>Gurugram Office: </b>&nbsp;&nbsp;11A TOWER B3 GF SPAZE I-TECH 
//                 PARK 
//                 &nbsp;SOHNA ROAD SEC 49 <br />
//                 &nbsp;&nbsp;GURUGRAM 122018
//               </p>

//               <p className="pfooter" id="footmail">
//                 <EmailIcon />
//                 &nbsp;info@aqtdirect.com
//               </p>
//               <div className="iconfooter">
//                 <FacebookRoundedIcon className="icon" />
//                 <XIcon className="icon" />
//                 <LinkedInIcon className="icon" />
//                 <TelegramIcon className="icon" />
//                 <YouTubeIcon className="icon" />
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="botfoot">
//           ©2018-2024 AQT Direct Limited. All Rights Reserved.
//           &nbsp;&nbsp;&nbsp;&nbsp;CIN : U70200MH2023PLC399088
//         </div>
//       </div>
//     </>
//   );
// }


import React from "react";
import "./Footer.css";
import aqt from "../../assets/images/aqt.png";
import { Link } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TelegramIcon from "@mui/icons-material/Telegram";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Container } from '@mui/system';


export default function Footer() {
  return (
    <>
    
    <div className="mainFooter">
    <Container>
        <div className="blocks">
          <div className="block1">
            <img src={aqt} alt="AQT Direct Logo" />
            <p style={{textAlign:'start'}}>
              AQT Direct Ltd is a distinguished Private Equity Consultancy
              dedicated to assisting individuals in becoming Partners and Angel
              Investors in Early-Stage, High-Growth Potential Public Businesses
              gearing up for Initial Public Offerings (IPOs). We provide a
              comprehensive range of services essential for Private Equity
              Investors, including Market Research, Due Diligence, Auditing,
              Transaction Execution, and Compliance of Private Placements.
            </p>
          </div>

          <div className="block2">
            <h1 className="anchorfoot">Quick Links</h1>
            <div className="anchor">
              <Link className="insideanch" to="/about">
                About Us
              </Link>
              <Link className="insideanch" to="/fundraise">
                Fundraise
              </Link>
              <Link className="insideanch" to="/smeloans">
                SME Loans
              </Link>
              <Link className="insideanch" to="/buysell">
              buysell
              </Link>
              <Link className="insideanch" to="/contactus">
                Contact Us
              </Link>
            </div>
          </div>

          <div className="block4">
            <h1 className="anchorfoot1">Our Offices</h1>
            <p className="pfooter">
              <LocationOnIcon />
              <b>Mumbai Office: &nbsp;&nbsp;&nbsp;</b> Enam Sambhav, G Block, Bandra Kurla Complex, Mumbai, Maharashtra 400051
            </p>
            <p className="pfooter">
              <LocationOnIcon />
              <b>Noida Office:</b> &nbsp; Unit 911,9th Floor, Iconic Tower, The  &nbsp; &nbsp;Corenthum, Sector 62, Noida, G.B Nagar, UP &nbsp; &nbsp; 201309, India
 
            </p>
            {/* <p className="pfooter">
              <LocationOnIcon />
              <b>Gurugram Office:</b> 11A Tower B3 GF, Spaze I-Tech Park, Sohna Road, Sector 49, Gurugram 122018
            </p> */}
            <p className="pfooter" id="footmail">
              <EmailIcon />
              &nbsp;info@aqtdirect.com
            </p>
            <div className="iconfooter">
              <FacebookRoundedIcon className="icon" />
              <XIcon className="icon" />
              <LinkedInIcon className="icon" />
              <TelegramIcon className="icon" />
              <YouTubeIcon className="icon" />
            </div>
          </div>
        </div>
        <div className="botfoot">
          ©2018-2025 AQT Direct Limited. All Rights Reserved.
          &nbsp;&nbsp;&nbsp;&nbsp;CIN : U70200MH2023PLC399088
        </div>
        </Container>
      </div>
  
     
    </>
  );
}
