// // new design
// import Footer from "../../components/Footer/Footer.js";
// import Navbar from "../../components/navbar/index.jsx";
// import "./Contact.css";
// import {
//   TextField,
//   Grid,
//   Typography,
//   Container,
//   Checkbox,
//   FormControlLabel,
//   Button,
//   Box,
// } from "@mui/material";
// import { useLocation } from "react-router-dom";
// import { useEffect, useState } from "react";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import axios from "axios";
// import contactBanner from "../../assets/images/contact1.jpg";
// import { contactapi } from "../../components/Api/Api.jsx";

// export default function Contact() {
//   const [formData, setFormData] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//     phone: "",
//     message: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post(contactapi, formData, {
//         headers: { "Content-Type": "application/json" },
//       });
//       toast.success(response.data.message);
//       setFormData({
//         firstName: "",
//         lastName: "",
//         email: "",
//         phone: "",
//         message: "",
//       });
//     } catch (error) {
//       toast.error(error?.message);
//     }
//   };

//   const { pathname } = useLocation();
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return (
//     <>
//       <Navbar />
//       <Box className="contact-banner">
//         <Typography className="contact-title">Contact Us</Typography>
//       </Box>
//       <Container className="contact-container">
//         <Grid container spacing={4} justifyContent="center">
//           <Grid item xs={12} sm={6}>
//             <Box>
//               <Typography variant="h4" className="contact-heading">
//                 Reach Out To Us
//               </Typography>
//               <Typography className="contact-description">
//                 Ready to take the next step? Contact AQT Direct Limited today to
//                 learn more about our services and how we can help you achieve
//                 your investment goals. Whether you're an investor looking for
//                 opportunities or a business seeking strategic advice, our team
//                 is here to assist you.
//               </Typography>
//               <Typography
//                 className="contact-description"
//                 sx={{ marginTop: 2, marginBottom: 2 }}
//               >
//                 Get in touch with us via phone, email, or through our online
//                 contact form. We look forward to hearing from you!
//               </Typography>
//             </Box>
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <Box>
//               <form onSubmit={handleSubmit} className="contact-form">
//                 <Grid container spacing={3}>
//                   <Grid item xs={12} sm={6}>
//                     <TextField
//                       fullWidth
//                       label="First Name"
//                       name="firstName"
//                       value={formData.firstName}
//                       onChange={handleChange}
//                       required
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={6}>
//                     <TextField
//                       fullWidth
//                       label="Last Name"
//                       name="lastName"
//                       value={formData.lastName}
//                       onChange={handleChange}
//                       required
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={6}>
//                     <TextField
//                       fullWidth
//                       label="Email"
//                       type="email"
//                       name="email"
//                       value={formData.email}
//                       onChange={handleChange}
//                       required
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={6}>
//                     <TextField
//                       fullWidth
//                       label="Phone Number"
//                       type="tel"
//                       name="phone"
//                       value={formData.phone}
//                       onChange={handleChange}
//                       required
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       fullWidth
//                       label="Message"
//                       name="message"
//                       value={formData.message}
//                       onChange={handleChange}
//                       multiline
//                       rows={4}
//                       required
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <FormControlLabel
//                       control={<Checkbox color="primary" required />}
//                       label="I agree to the terms and conditions"
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <Button
//                       type="submit"
//                       variant="contained"
//                       color="primary"
//                       fullWidth
//                     >
//                       Submit
//                     </Button>
//                   </Grid>
//                 </Grid>
//               </form>
//             </Box>
//           </Grid>
//         </Grid>
//       </Container>
//       <Footer />
//       <ToastContainer />
//     </>
//   );
// }

// Contact.jsx
import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Typography,
  Container,
  Button,
  Box,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Navbar from "../../components/navbar/index.jsx";
import Footer from "../../components/Footer/Footer.js";
import contactBanner from "../../assets/images/contact1.jpg";
import { contactapi } from "../../components/Api/Api.jsx";
import "./Contact.css";

export default function Contact() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(contactapi, formData, {
        headers: { "Content-Type": "application/json" },
      });
      toast.success(response.data.message);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
      });
    } catch (error) {
      toast.error(error?.message || "An error occurred");
    }
  };

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Navbar />
      <div className="main-div">
        <Box className="contact-banner">
          {/* <Typography className="contact-title2">Contact Us</Typography> */}
        </Box>
        <Container className="contact-main-container">
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" className="contact-heading">
                Get in Touch
              </Typography>
              <Typography className="contact-description">
                Whether you have a question about our services, pricing, or
                anything else, our team is ready to answer all your questions.
                Use the form to contact us or reach out directly via email or
                phone.
              </Typography>
              <Box className="contact-info">
                {/* <Typography variant="h6">📞 Phone: +1 (123) 456-7890</Typography> */}
                <Typography variant="h6">
                  📧 <strong>Email: &nbsp;</strong> info@aqtdirect.com
                </Typography>
                <Typography variant="h6">
                  📍 <strong> Address 1: &nbsp;</strong> Enam Sambhav, G Block,
                  Bandra Kurla Complex, Mumbai, Maharashtra 400051
                </Typography>
                <Typography variant="h6">
                  📍<strong> Address 2 : &nbsp;</strong> Unit 911,9th Floor,
                  Iconic Tower, The Corenthum, Sector 62, Noida, G.B Nagar, UP
                  201309, India
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="form-container">
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="First Name"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Last Name"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Email Address"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Phone Number"
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Your Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        multiline
                        rows={4}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                      >
                        Send Message
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}
